import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import LanguagesModal from './Languages/LanguagesModal';
import Languages from './Languages/Languages';

import useScroll from '../utils/useScroll';
import LanguageSelect from './LangugeSelect';
import getLangCode from '../utils/getLangCode';
import { findCountry, isClient, underscoreToSpace } from '../helpers';

import SvgArrow from '../assets/images/vectors/icons/dropdown-arrow.inline.svg';
import logoImg from '../assets/images/vectors/logo.png';
import iconMenu from '../assets/images/vectors/icons/menu.svg';
import iconClose from '../assets/images/vectors/icons/close.svg';
import iconFlagEspana from '../assets/images/vectors/languages/flag_Espana.png';
import iconFlagFrance from '../assets/images/vectors/languages/flag_France.png';
import iconFlagItalia from '../assets/images/vectors/languages/flag_Italia.png';
import iconFlagPortugal from '../assets/images/vectors/languages/flag_Portugal.png';
import iconFlagUnitedKingdom from '../assets/images/vectors/languages/flag_United Kingdom.png';
import iconFlagChina from '../assets/images/vectors/languages/flag_China.png';

const LoginBtn = () => (
  <a
    className="c-btn--2"
    href="https://www.bebackoffice.com/"
    target="_blank"
    rel="noopener noreferrer"
  >
    Log in
  </a>
);

export const getCurentLang = () => {
  const code = getLangCode();
  let result;

  switch (code) {
    case 'en':
      result = {
        flag: iconFlagUnitedKingdom,
        code,
      };
      break;
    case 'fr':
      result = {
        flag: iconFlagFrance,
        code,
      };
      break;
    case 'pt':
      result = {
        flag: iconFlagPortugal,
        code,
      };
      break;
    case 'zh':
      result = {
        flag: iconFlagChina,
        code,
      };
      break;
    case 'es':
      result = {
        flag: iconFlagEspana,
        code,
      };
      break;
    case 'it':
      result = {
        flag: iconFlagItalia,
        code,
      };
      break;

    default:
      result = {
        flag: iconFlagUnitedKingdom,
        code: 'en',
      };
      break;
  }

  return result;
};

const Header = () => {
  if (!isClient) {
    return null;
  }
  // Header and mobile menu
  const { isStickyHeader } = useScroll();
  const localStorageFlag = localStorage.getItem('countryFlag');
  const localStorageName = localStorage.getItem('countryName');

  const urlParams = new URLSearchParams(window.location.search);
  const countryParams = urlParams.get('country');
  const countryFlag = findCountry(countryParams);
  const regionCountryFlag = (localStorageFlag && localStorageName) || countryParams;

  const [isOpenMobileMenu, setStateMobileMenu] = useState(false);
  const [currentLang, setCurrentLang] = useState(null);
  const [isOpenLanguagesModal, setStateLanguagesModal] = useState(false);
  // const code = getLangCode();

  const showLanguagesModal = () => {
    setStateLanguagesModal(true);
  };

  const hideLanguagesModal = () => {
    setStateLanguagesModal(false);
  };

  const handleStateMobileMenu = () => {
    setStateMobileMenu(!isOpenMobileMenu);

    const body = document.querySelector('body');
    if (isOpenMobileMenu) {
      body.style.overflow = 'auto';
    } else {
      body.style.overflow = 'hidden';
    }
  };

  useEffect(() => {
    if (!isOpenMobileMenu) {
      const body = document.querySelector('body');
      body.style.overflow = 'auto';
    }
  });

  const changeFlagFromParams = () => {
    if (countryParams) {
      const countryName = underscoreToSpace(countryParams);
      const country = findCountry(countryName);
      setCurrentLang({
        flag: country.flag,
        name: countryName,
      });
    }
  };

  useEffect(() => {
    if (countryParams) {
      changeFlagFromParams();
    }
  }, [countryParams]);

  useEffect(() => {
    if (countryParams && countryFlag) {
      localStorage.setItem('countryName', underscoreToSpace(countryParams));
      localStorage.setItem('countryFlag', countryFlag.flag);
    }

    if (localStorageFlag && localStorageName) {
      setCurrentLang({
        flag: localStorageFlag,
        name: localStorageName,
      });
    }

    if (!countryParams && localStorageFlag && localStorageName) {
      setCurrentLang({
        flag: localStorageFlag,
        name: localStorageName,
      });
    }

    if (countryParams) {
      changeFlagFromParams();
    }

    if (!regionCountryFlag) {
      setCurrentLang({
        flag: iconFlagUnitedKingdom,
        name: 'United Kingdom',
      });
      showLanguagesModal();
    }
  }, []);

  return (
    <>
      <header className={`header ${isStickyHeader ? 'header--sticky' : ''}`}>
        <div className="container">
          <div className="header__wrapper">
            <div className="header__wrapper__l">
              <div className="header__wrapper__l__logo">
                <Link to="/">
                  <img src={logoImg} alt="logo" />
                </Link>
              </div>
              <div className="header__wrapper__l__nav">
                <nav>
                  <li className="li-hover--lvl-1">
                    <div>
                      <span>Opportunity</span>
                      <i>
                        <SvgArrow />
                      </i>
                    </div>

                    <div className="dropdown dropdown--lvl-1">
                      <ul>
                        <li>
                          <Link to="/opportunity/">BE Opportunity</Link>
                        </li>
                        <li>
                          <Link to="/life-at-be/">Life at BE</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="li-hover--lvl-1">
                    <div>
                      <span>Products</span>
                      <i>
                        <SvgArrow />
                      </i>
                    </div>
                    <div className="dropdown dropdown--lvl-1">
                      <ul>
                        <li className="li-hover--lvl-2">
                          <div>
                            <Link to="/live/">Live</Link>
                            <i>
                              <SvgArrow />
                            </i>
                          </div>

                          <div className="dropdown dropdown--lvl-2">
                            <ul>
                              <li>
                                <Link to="/quest/">Quest</Link>
                              </li>
                              {/*<li>*/}
                              {/*  <Link to="/triva/">Triva</Link>*/}
                              {/*</li>*/}
                              {/*<li>*/}
                              {/*  <Link to="/iride/">iRide</Link>*/}
                              {/*</li>*/}
                              {/*<li>*/}
                              {/*  <Link to="/latent-pay/">Latent Pay</Link>*/}
                              {/*</li>*/}
                              <li>
                                <Link to="/quantum/">Quantum</Link>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="li-hover--lvl-2">
                          <div>
                            <Link to="/learn/">Learn</Link>
                            <i>
                              <SvgArrow />
                            </i>
                          </div>
                          <div className="dropdown dropdown--lvl-2">
                            <ul>
                              {/* <li> */}
                              {/* <Link to="/shift/">SHiFT</Link> */}
                              {/* </li> */}
                              <li>
                                <Link to="/mind-hub/">Mind Hub</Link>
                              </li>
                              <li>
                                <Link to="/wow/">WOW</Link>
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>
                  {/*<li>*/}
                  {/*  <Link to="/pricing/">Pricing</Link>*/}
                  {/*</li>*/}
                  <li className="li-hover--lvl-1">
                    <div>
                      <span>About us</span>
                      <i>
                        <SvgArrow />
                      </i>
                    </div>
                    <div className="dropdown dropdown--lvl-1">
                      <ul>
                        {/*<li>*/}
                        {/*  <Link to="/our-offices/">Our Offices</Link>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*  <Link to="/our-story/">Our story</Link>*/}
                        {/*</li>*/}
                        <li>
                          <Link to="/blog/">Blog</Link>
                        </li>
                        <li>
                          <Link to="/contact-us/">Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                </nav>
              </div>
            </div>
            <div className="header__wrapper__r">
              <div className="header__wrapper__r__languages">
                {currentLang && (
                  <button
                    className="c-btn-reset header__wrapper__r__languages__wrapper"
                    type="button"
                    onClick={showLanguagesModal}
                  >
                    <img src={currentLang.flag} alt="language" />
                    <span>{currentLang.name}</span>
                  </button>
                )}
              </div>
              <LanguageSelect />
              {/*<div className="header__wrapper__r__login">*/}
              {/*  <LoginBtn />*/}
              {/*</div>*/}
              <div className="header__wrapper__r__mobile" onClick={handleStateMobileMenu}>
                <img src={isOpenMobileMenu ? iconClose : iconMenu} alt="icon" />
              </div>
            </div>
          </div>

          {isOpenMobileMenu && <MobileMenu currentLang={currentLang} />}
        </div>
      </header>

      {isOpenLanguagesModal && <LanguagesModal handleClose={hideLanguagesModal} />}
    </>
  );
};

const MobileMenu = ({ currentLang }) => {
  const [route, setRoute] = useState('/');

  const handleRoute = (value) => {
    setRoute(value);
  };

  const renderSubmenu = () => {
    let result;

    switch (route) {
      case 'opportunity':
        result = <MobileNavOpportunity />;
        break;
      case 'products':
        result = <MobileNavProducts />;
        break;
      case 'about-us':
        result = <MobileNavAboutUs />;
        break;
      case 'languages':
        result = <Languages />;
        break;
    }

    return result;
  };

  return (
    <div className="header-mobile__wrapper">
      {route === '/' ? (
        <div className="header-mobile__wrapper__lvl-1">
          <nav>
            <li>
              <div onClick={() => handleRoute('opportunity')}>
                <span>Opportunity</span>
                <i>
                  <SvgArrow />
                </i>
              </div>
            </li>
            <li>
              <div onClick={() => handleRoute('products')}>
                <span>Products</span>
                <i>
                  <SvgArrow />
                </i>
              </div>
            </li>
            <li>
              <Link to="/pricing/">Pricing</Link>
            </li>
            <li>
              <div onClick={() => handleRoute('about-us')}>
                <span>About us</span>
                <i>
                  <SvgArrow />
                </i>
              </div>
            </li>
          </nav>
          {/*<div className="header-mobile__wrapper__lvl-1__btn">*/}
          {/*  <LoginBtn />*/}
          {/*</div>*/}
          {/*
          <button
            className="c-btn-reset header-mobile__wrapper__lvl-1__languages"
            type="button"
            onClick={() => handleRoute('languages')}
          >
            <img src={currentLang.flag} alt="language" />
            <span>{currentLang.name}</span>
          </button>
          */}
        </div>
      ) : (
        <div className="header-mobile__wrapper__lvl-2">
          <div className="header-mobile__wrapper__lvl-2__back" onClick={() => handleRoute('/')}>
            <i>
              <SvgArrow />
            </i>
            <span>back to the main menu</span>
          </div>
          <div className="header-mobile__wrapper__lvl-2__nav">{renderSubmenu()}</div>
        </div>
      )}
    </div>
  );
};

const MobileNavOpportunity = () => (
  <>
    <div className="header-mobile__wrapper__lvl-2__nav__title">Opportunity</div>
    <nav>
      <div className="nav-links">
        <li>
          <Link to="/opportunity/">BE Opportunity</Link>
        </li>
        <li>
          <Link to="/life-at-be/">Life at BE</Link>
        </li>
      </div>
    </nav>
  </>
);
const MobileNavProducts = () => (
  <>
    <div className="header-mobile__wrapper__lvl-2__nav__title">Products</div>
    <nav>
      <div className="nav-category">
        <Link to="/live/">Live</Link>
      </div>
      <div className="nav-links">
        <li>
          <Link to="/quest/">Quest</Link>
        </li>
        {/*<li>*/}
        {/*  <Link to="/triva/">Triva</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to="/iride/">iRide</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to="/latent-pay/">Latent Pay</Link>*/}
        {/*</li>*/}
        <li>
          <Link to="/quantum/">Quantum</Link>
        </li>
      </div>

      <div className="nav-category">
        <Link to="/learn/">Learn</Link>
      </div>
      <div className="nav-links">
        <li>
          <Link to="/shift/">SHiFT</Link>
        </li>
        <li>
          <Link to="/mind-hub/">Mind Hub</Link>
        </li>
        <li>
          <Link to="/wow/">WOW</Link>
        </li>
      </div>
    </nav>
  </>
);

const MobileNavAboutUs = () => (
  <>
    <div className="header-mobile__wrapper__lvl-2__nav__title">About us</div>
    <nav>
      <div className="nav-links">
        <li>
          <Link to="/our-offices/">Our Offices</Link>
        </li>
        <li>
          <Link to="/our-story/">Our story</Link>
        </li>
        <li>
          <Link to="/blog/">Blog</Link>
        </li>
        <li>
          <Link to="/contact-us/">Contact Us</Link>
        </li>
      </div>
    </nav>
  </>
);

export default Header;
